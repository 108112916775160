import React from 'react'

export default function Temp() {
  return (
    <div class="p-8 md:pt-24 h-screen w-screen flex items-center justify-center bg-zog bg-no-repeat bg-cover bg-blend-normal bg-center shadow-2xl">
    <div className='grid md:grid-cols-2 md:gap-5 text-white '>
      {/* <div className='flex flex-col sm:col-span-1 justify-center py-20 text-center'>
        <h1 className= 'md:text-left py-3 text-6xl md:text-7xl font-bold'>Zoë Felfle</h1>
        <p className='text-3xl md:text-4xl'>Student writer</p>
      </div> */}
      {/* <div className='grid max-w-[500px] place-items-center'>
        <div className='fill-blue-500 '>
        </div>
      </div> */}
    </div>
  </div>
  )
}
