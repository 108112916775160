import React from 'react'
import { useForm, ValidationError } from '@formspree/react';
import ThankYouContact from '../components/ThankYouContact'

export default function ContactForm() {

const [state, handleSubmit] = useForm("meqdqrnb");
  if (state.succeeded) {
      return <ThankYouContact/>
  }

  return (
    <div className='w-screen h-full bg-gradient-to-tr bg-pink-300 font-outfit pb-24 pt-24'>
      <div className='pt-20 rounded-xl'>
          <div className=' grid rounded-xl  text-center place-items-center text-white'>

          <div className='pt-10'>
            <form className='bg-zinc-600 p-5 pt-10 rounded-2xl w-[300px] h-[450px] md:w-[500px] md:h-[500px] drop-shadow-xl ' onSubmit={handleSubmit}>
                <div className='grid  grid-cols-1 max-w-[500px] justify-center text-black'>
                <label className='font-bold text-white pb-2' htmlFor="email">Email Address</label>
                <input className='rounded-md focus:outline-none focus:ring focus:ring-pink-100 ' id="email" type="email" name="email" required={true}/>
                <p className='font-bold text-white pb-2 pt-2'>Full Name</p>
                <input className='rounded-md focus:outline-none focus:ring focus:ring-pink-100 ' id="Name" name="Name" required={true}/>
                <ValidationError prefix="Email"  field="email" errors={state.errors}/> 
                <p className='font-bold text-white pb-2 pt-2'>Message</p>
                <textarea className='border h-[150px] rounded-md focus:outline-none focus:ring focus:ring-pink-100 ' required={true} id="message" name="message"/>
                <ValidationError prefix="Message" field="message"errors={state.errors}/>
                <div className='pt-8'>
                    <button className='font-bold hover:bg-pink-100 text-pink-300 pb-2 pt-2 bg-white rounded-xl w-[100px] text-center ' type="submit" disabled={state.submitting}>Submit</button>
                </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
