import React from 'react'
import ContactForm from '../components/ContactForm'

export default function Contact() {
  return (
    <div>
      <ContactForm />
    </div>
  )
}
