import React from 'react'
import {
  FaInstagram,
  FaLinkedin,
} from 'react-icons/fa'
import{
  MdEmail
} from 'react-icons/md'

export default function Footer() {
  return (
    <div className='w-screen h-full bg-zinc-100 py-4 pb-8 px-2 font-outfit'>
        <div className=' border-t-2 border-zinc-600 flex flex-col max-w-[1240px] px-2 mx-auto justify-between sm:flex-row text-center text-zinc-600'>
        <p className='font-bold'>© 2022 Zoë Felfle </p>
            <div>
            <div className='flex justify-between sm:w-[300px] pt-2 text-2xl'>
              <a className="hover:text-pink-300 transition duration-150 ease-in-out " href="https://www.linkedin.com/in/zo%C3%AB-felfle/"><FaLinkedin /></a>
              <a className="hover:text-pink-300 transition duration-150 ease-in-out " href="https://www.instagram.com/a.caffeinated.reader/"><FaInstagram /></a>
              <a className="hover:text-pink-300 transition duration-150 ease-in-out " aria-disabled="false" target="_self" href="mailto:zoefelfle1.4@gmail.com?subject=Lets Talk!"><MdEmail /></a>
            </div>
            </div>
        </div>
    </div>
  )
}
