import React from 'react'
import Works from '../components/Works'

export default function Portfolio() {
  return (
    <>
      <Works />
    </>
  )
}
