import React from 'react'

export default function Works() {
  return (
    <div className='bg-zinc-700 h-full w-screen text-white pt-10 pb-20 font-outfit text-center'>
        <div className='grid grid-cols-1 items-center text-center my-20'>
            <h1 className='font-bold text-6xl text-pink-300'>My Works</h1>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-4'>
          <div className='border'>
            <p>Writing or image</p>
          </div>
          <div className='flex justify-center items-center '>
            <div>
              <h1 className='text-center text-2xl xl:text-4xl pb-2 text-pink-300'>Lorem.</h1>
              <p className='xl:text-2xl'>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore aut facere officia enim, quo mollitia explicabo quos
                odio similique, autem non rerum. Mollitia a, consectetur obcaecati asperiores, modi fugiat ad perspiciatis molestiae
                commodi aut ab temporibus perferendis reprehenderit expedita? Sapiente maiores laborum exercitationem cumque illo asperiores
                quisquam commodi, ipsum repudiandae.
              </p>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-16'>
          <div className='flex border lg:hidden'>
            <p>Writing or image</p>
          </div>
          <div className='flex justify-center items-center '>
            <div>
            <h1 className='text-center text-2xl xl:text-4xl pb-2 text-pink-300'>Lorem.</h1>
            <p className='xl:text-2xl'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore aut facere officia enim, quo mollitia explicabo quos
              odio similique, autem non rerum. Mollitia a, consectetur obcaecati asperiores, modi fugiat ad perspiciatis molestiae
              commodi aut ab temporibus perferendis reprehenderit expedita? Sapiente maiores laborum exercitationem cumque illo asperiores
              quisquam commodi, ipsum repudiandae.
            </p>
            </div>
          </div>
          <div className='hidden lg:flex lg:border'>
            <p className='text-center'>Writing or image</p>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-10'>
            <div className='border'>
            <p>Writing or image</p>
          </div>
          <div className='flex justify-center items-center '>
            <div>
              <h1 className='text-center text-2xl xl:text-4xl pb-2 text-pink-300'>Lorem.</h1>
              <p className='xl:text-2xl'>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore aut facere officia enim, quo mollitia explicabo quos
                odio similique, autem non rerum. Mollitia a, consectetur obcaecati asperiores, modi fugiat ad perspiciatis molestiae
                commodi aut ab temporibus perferendis reprehenderit expedita? Sapiente maiores laborum exercitationem cumque illo asperiores
                quisquam commodi, ipsum repudiandae.
              </p>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 px-2 md:px-20 pt-16'>
            <div className='flex border lg:hidden'>
            <p>Writing or image</p>
            </div>
          <div className='flex justify-center items-center '>
            <div>
            <h1 className='text-center text-2xl xl:text-4xl pb-2 text-pink-300'>Lorem.</h1>
            <p className='xl:text-2xl'>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore aut facere officia enim, quo mollitia explicabo quos
              odio similique, autem non rerum. Mollitia a, consectetur obcaecati asperiores, modi fugiat ad perspiciatis molestiae
              commodi aut ab temporibus perferendis reprehenderit expedita? Sapiente maiores laborum exercitationem cumque illo asperiores
              quisquam commodi, ipsum repudiandae.
            </p>
            </div>
          </div>
          <div className='hidden lg:flex lg:border'>
            <p className='text-center'>Writing or image</p>
          </div>
        </div>
    </div>
  )
}
