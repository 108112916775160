import React from 'react'

export default function ThankYouContact() {
  return (
    <div name='about' className='w-screen h-screen pt-40 font-outfit bg-pink-300 text-white'>
        <div className='max-w-[1240px] mx-auto'>
            <div className='text-center'>
                <h2 className='text-4xl md:text-5xl font-bold'>Thank you for your message! I will get back to you as soon as possible.</h2>
            </div>
        </div>
    </div>
  )
}
