import React, { useState } from 'react'
import { HiMenu, HiX } from "react-icons/hi";
import { Link } from 'react-router-dom';
import resume from "./resume.pdf"

export default function Nav() {

  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className={!nav ? "absolute text-zinc-600 font-outfit font-bold bg-zinc-100 w-screen pt-8 pb-8" : 'absolute text-zinc-600 font-outfit font-bold bg-zinc-200 w-screen pb-8 md:pt-8'}>
      <div className='grid grid-cols-2 md:flex'>
        <div >
          <h1 className=' px-8 text-3xl'>
              Zoë Felfle 
          </h1>
        </div>
        <div className='grid justify-end content-center pr-8 md:hidden' >
          {!nav ? <button onClick={handleClick}><HiMenu className='text-4xl hover:text-pink-300 transition duration-150 ease-in-out'/></button> : 
          <div className="w-full sm:h-[50px] md:h-[80px] z-10 fixed drop-shadow-lg pt-8">
            <div className='pl-40'>
              <button onClick={handleClick}><HiX className=' text-4xl hover:text-pink-300 transition duration-150 ease-in-out'/></button>
            </div>
          </div>}
        </div>
        <div className='hidden md:grid md:grid-flow-col content-end gap-8 text-sm md:text-xl'>
          <button><p className='hover:text-pink-300 transition duration-150 ease-in-out'><Link to="/">About</Link></p></button>
          <button><p className='hover:text-pink-300 transition duration-150 ease-in-out'><Link to="/portfolio">Portfolio</Link></p></button>
          {/* <button><p className='hover:text-pink-300 transition duration-150 ease-in-out'><Link to="/zines">Zines</Link></p></button> */}
          <button><p className='hover:text-pink-300 transition duration-150 ease-in-out'><Link to="/contact">Contact</Link></p></button>

          <button><a href={resume} target="_blank"><p className=' transition duration-150 ease-in-out p-1 rounded-md  text-white bg-zinc-600 hover:bg-pink-300'>Resume</p></a></button>
        </div>
        <div className={!nav ? "hidden" : " fixed bg-zinc-800 w-screen h-screen text-white md:hidden"}>
          <ul className="px-8 pt-20 flex flex-col gap-4">
              <button onClick={handleClick}><li className="hover:text-pink-300 text-2xl"><Link to="/">About</Link></li></button>
              <button onClick={handleClick}><li className="hover:text-pink-300 text-2xl"><Link to="/portfolio">Portfolio</Link></li></button>
              {/* <button onClick={handleClick}><li className="hover:text-pink-300 text-2xl"><Link to="/zines">Zines</Link></li></button> */}
              <button onClick={handleClick}><li className="hover:text-pink-300 text-2xl"><Link to="/contact">Contact</Link></li></button>
              <button><a href={resume} target="_blank"><p className='hover:text-pink-300 text-2xl'>Resume</p></a></button>

          </ul>
        </div>
      </div>
     </div>
  )
}
