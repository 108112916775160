import React from 'react'

export default function About() {
  return (
    <div className='bg-zinc-700 w-screen h-screen font-outfit text-white'>
      <div className='p-8'>
        <div className='rounded-lg '>
          <h1 className='font-bold text-6xl text-center text-pink-300'>Welcome to my website!</h1>
        </div>
      </div>
    </div>
  )
}
