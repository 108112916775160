import React from 'react'
import Footer from '../components/Footer';
import Head from '../components/Head';
import About from '../components/About';


export default function Home() {
  return (
    <>
      <Head/>
      <About/>
    </>
  )
}
